<template>
  <a-menu
    :mode="mode"
    :open-keys="openKeys"
    style="width: 256px"
    @openChange="onOpenChange"
    theme="dark"
  >
    <template v-for="menuInfo in menuList">
      <template v-if="!menuInfo.meta.hideInMenu">
        <a-sub-menu :key="menuInfo.path">
          <span slot="title">
            <span>{{ menuInfo.meta.title }}</span>
          </span>
          <a-menu-item v-for="item in menuInfo.children" :key="item.path">
            {{ item.meta.title }}
          </a-menu-item>
        </a-sub-menu>
      </template>
    </template>
  </a-menu>
</template>

<script>
import mixin from "./mixin";
import SubMenu from "./SubMenu";
export default {
  name: "AxMenu",
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      default: "inline",
    },
    defaultOpenKeys: {
      type: Array,
      default: () => [
        "/views/communityGovernance/basicOrganization/personnelManage/residentFile/index",
      ],
    },
    iconSize: {
      type: Number,
    },
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  components: { SubMenu },
  data() {
    return {
      openKeys: [],
      rootSubmenuKeys: [],
    };
  },
  computed: {
    selectedKeysFromStore() {
      return this.$store.state.community.meunMsg;
    },
  },
  watch: {
    // 监听 Vuex 状态变化，确保 selectedKeys 更新
    selectedKeysFromStore(newVal) {
      console.log(newVal);
    },
  },
  created() {
    this.menuList.forEach((item) => {
      this.rootSubmenuKeys.push(item.path);
    });
  },
  methods: {
    /**
     * 更新菜单
     */
    updateMenu() {
      const routes = this.$route.matched.concat();
      const openKeys = this.openKeys;
      // pop删除并返回数组最后一个元素（会改变原数组）
      const currentRoute = routes.pop();
      // 设置当前高亮的菜单
      this.selectedKeys = currentRoute.meta.activeMenu
        ? [currentRoute.meta.activeMenu]
        : [currentRoute.path];
      // 获取展开的菜单key并存起来
      routes.forEach((item) => {
        if (!openKeys.includes(item.path)) {
          openKeys.push(item.path);
        }
      });
      this.collapsed || this.mode === "horizontal"
        ? (this.cachedOpenKeys = openKeys)
        : (this.openKeys = openKeys);
    },
    // SubMenu 展开/关闭的回调
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
};
</script>
<style>
</style>
